<template>
	<div class="full-height size-px-14 position-relative">
		<div class="header">
			<div
				class="top top_mafi "
			><!--trans-->
				<div class="util shadow">
					<button
						class="arrow_white"
						@click="goBack"
					>
						<i class="icon icon-arrow-left"></i>
					</button>
				</div>
				<h2 class="page_tit ml-15">{{ $language.shorts.title_shorts_item }} <!-- 숏플 수정 --> </h2>
				<div
					class="util size-px-16"
					style="margin-left: auto;"
					@click="postSave"
				>
					<span
						class="color-white"
					>{{ $language.common.complete }} <!-- 완료 --></span>
				</div>
			</div>
		</div>
		<div
			class="full-height"
		>
			<div
				class="flex-column justify-center position-relative bg-white pt-50 wrap-d"
			>
				<div
					class="radius-20 overflow-hidden flex-column justify-center position-relative"
					style="max-height: 300px; padding: 30px 20px 20px 20px;"
				>
					<video
						width="fit-content"
						autoplay
						loop
						playsinline

						id="video"
						ref="video"
						class="radius-20"
					>
					</video>
					<div
						class="position-absolute-full flex-column justify-center items-center"
						@click="pausePlay"
					>
						<v-icon
							v-if="item.paused"
							class="color-white" large>mdi-play-circle-outline</v-icon>
						<v-icon
							v-else
							class="color-white" large>mdi-pause-circle-outline</v-icon>
					</div>
				</div>
				<div
					class="prl-20"
				>
					<h6 class="size-px-16 font-weight-500 color-333">{{ $language.shorts.title_shorts_contents }} <!-- 숏플 내용 --> <span class="color-red">*</span></h6>
					<textarea
						v-model="item.post_contents"
						class="width-100 pa-20 radius-10 mt-15 overflow-y-auto"
						rows="5"
						:placeholder="$language.shorts.txt_shorts_contents"
						maxlength="140"
					></textarea>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'CartelShortsItemDetail'
	, components: { }
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: 'shorts'
				, title: 'shorts'
				, type: 'cartel_sub'
				, not_footer: true
				, not_header: true
			}
			, file_max: 1
			, files: []
			, upload_files: [
				{src:''}
			]
			, post_file_list: {
			}
			, item_cover: {
				src:''
			}
			, item_board_config: {

			}
			, is_show: false
			, mode: 'empty'
			, post_contents: ''
			, appendix_file_div_code: 'CA01000001'
			, file_upload_code: 'CM00700015'
			, stremg_video_file_list: []
			, item_aws: {

			}
			, item_video: {

			}
			, item: {

			}
			, player: {

			}
			, player0: {

			}
			, playerEvent: {

			}
			, playerEvent0: {

			}
		}
	}
	, methods: {
		goBack: function(){
			if(this.$route.params.bbs_id){
				this.$emit('goBack')
			}else if(this.mode == 'edit'){
				this.mode = 'empty'
			}else{
				if(this.upload_files.length > 0){
					this.upload_files = []
					this.files = []
					this.post_file_list = {}
				}else{
					this.$emit('goBack')
				}
			}
		}
		, getBoardConfig: async function() {
			try {
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_board_config
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.$route.params.b_id
					}
					, type: true
				})

				if (result.success) {
					this.item_board_config = result.data
				} else {
					if(result.code == 'E001201021'){
						this.$bus.$emit('notify', {type: 'error', message: result.result_msg})
					}
					throw result.message
				}
			} catch (e) {
				console.log(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
			}
		}
		, toEdit: function(){
			this.mode = 'edit'
		}
		, postSave: async function(){
			if(!this.item.post_contents){
				this.$bus.$emit('notify', { type: 'error', message: this.$language.shorts.error_shorts_contents})
				return false
			}
			try {
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.put_shorts
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.$route.params.b_id
						, bulletin_number: this.$route.params.bbs_id
						, post_contents: this.item.post_contents
					}
					, type: true
				})

				if (result.success) {
					this.$bus.$emit('goBack')
				} else {
					if(result.code == 'E001201021'){
						this.$bus.$emit('notify', {type: 'error', message: result.result_msg})
					}
					throw result.message
				}
			} catch (e) {
				console.log(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
			}
		}

		, postFile: async function(file){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_file
					, data: {
						member_number: this.user.member_number
						, file_upload_code: this.file_upload_code
						, appendix_file_div_code: this.appendix_file_div_code
						, appendix_file_name: file
					}
					, multipart: true
					, type: true
				})

				if(result.success){
					this.post_file_list = {
						post_appendix_file_div_code: this.appendix_file_div_code
						, post_appendix_file_indexnumber: 1
						, post_file_url: result.data.file_url
					}

				}else{
					throw result.message
				}
			}catch (e) {
				console.log('postFile error', e)
				this.$bus.$emit('notify', { type: 'error', message: e})
				return false
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_shorts
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.$route.params.b_id
						, bulletin_number: this.$route.params.bbs_id
					}
					, type: true
				})

				if(result.success){
					this.item = result.data
					setTimeout(() => {
						this.loadVideo()
					}, 1000)

				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, setSteamVideo: function(item){

			this.$set(this.item, 'src', item.post_appendix_file_org_url)
console.log(this.item.src)
			setTimeout( () => {

				if(this.player){

					this.player.src(this.item.src);
					this.player.play();
					console.log('play')
				}
			}, 1000)
		}

		, loadVideoEvent: function(err){
			if(err.code === 404){
				setTimeout( () => {
					this.player.src(this.item.src);
					this.player.play();
				}, 5000)
			}
		}

		, loadVideo: function(){
			console.log('player initialize start')
			window.registerIVSTech(window.videojs)
			// IVS Player와 Video.js 통합 설정
			const videoOptions = {
				techOrder: ["AmazonIVS"],
				autoplay: true,
				playsinline: true
			};
			console.log(document.getElementById('video'))
			this.player = window.videojs('video', videoOptions, () => {
				console.log('Player is ready to use');
			});
			console.log(this.player)
			this.player.src(this.item.src);
			this.player.play();
			this.player.responsive(true);
			console.log(this.player.tags)

			this.playerEvent = this.player.getIVSEvents().PlayerEventType;
			this.player.getIVSPlayer().addEventListener(this.playerEvent.ERROR, this.loadVideoEvent, true);
			this.player.on('waiting', function () {
				console.log('Video is buffering...');
				// 버퍼링 상태에서 필요한 처리를 수행
			});

			this.player.on('canplay', function () {
				console.log('Video can start playing.');
				this.on_video = true
				// 버퍼링이 완료되고 재생이 시작 가능한 상태에서 필요한 처리를 수행
			});

			this.player.on('playing', function () {
				console.log('Video is playing.');
				this.on_video = true
				// 비디오 재생이 시작될 때 필요한 처리를 수행
			});

			this.player.on('stalled', function () {
				console.log('Video playback stalled.');
				this.on_video = false
				// 재생이 중단되거나 지연될 때 필요한 처리를 수행
			});

			this.is_streaming = true

			let item = this.item
			this.setSteamVideo(item.post_file_list[1])

		}
		, pausePlay: function(){
			let d = this.player

			if(d.paused()){
				d.play()
				this.$set(this.item, 'paused', false)
			}else{
				d.pause()
				this.$set(this.item, 'paused', true)
			}
		}
	}
	, destroyed() {
		if(this.player){
			this.player.dispose()
		}
	}
	, created() {
		this.$bus.$emit('onLoad', this.program)
		this.getBoardConfig()
		this.getData()
	}

}
</script>

<style>

.wrap-d div{
	width: 100%; height: 100%; overflow: hidden;
}
.wrap-d video {
	display: block; border-radius: 20px; max-width: 100%; max-height: 250px; margin: 0 auto;
}
textarea{border: 1px solid #dadada;}
textarea::placeholder{color: #bbb;}
</style>